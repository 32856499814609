import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { APIS } from 'src/utility/apis'
import { API_ENDPOINT } from 'src/utility/constants'

export const leadershipApi = createApi({
  reducerPath: 'leadership',
  baseQuery: fetchBaseQuery({
    baseUrl: API_ENDPOINT,
    prepareHeaders: (headers, { getState }) => {
      const token = getState()?.auth?.userData?.token

      headers.set('Accept', 'application/json')
      token && headers.set('Authorization', `Token ${token}`)
    },
  }),
  endpoints: (builder) => ({
    getLeaderPoints: builder.query({
      query: ({ is_admin }) => ({
        url: APIS.leadership.points,
        params: { is_admin },
      }),
    }),
    GetLeadershipRule: builder.mutation({
      query: (payload) => ({
        url: `${APIS.leadership.rule}?operator_id=${payload}`,
        method: 'GET',
      }),
    }),
    GetTrendingRule: builder.mutation({
      query: (payload) => ({
        url: `${APIS.trending.rule}?operator_id=${payload}&is_slower=1`,
        method: 'GET',
      }),
    }),
  }),
})

export const { useGetLeaderPointsQuery, useGetLeadershipRuleMutation, useGetTrendingRuleMutation } =
  leadershipApi
