export const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT
export const AVATARS = [
  require('../assets/images/avatars/avatar1.png'),
  require('../assets/images/avatars/avatar2.png'),
  require('../assets/images/avatars/avatar3.png'),
  require('../assets/images/avatars/avatar4.png'),
  require('../assets/images/avatars/avatar5.png'),
  require('../assets/images/avatars/avatar6.png'),
  require('../assets/images/avatars/avatar7.png'),
  require('../assets/images/avatars/avatar8.png'),
  require('../assets/images/avatars/avatar9.png'),
  require('../assets/images/avatars/avatar10.png'),
  require('../assets/images/avatars/avatar11.png'),
  require('../assets/images/avatars/avatar12.png'),
]
