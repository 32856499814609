import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isAuthenticated: false,
  isAdmin: false,
  userData: { graph_id: 0, email: '', id: 0, username: '', token: '', user_profile: {} },
  userTheme: { header_fixed: true, sidebar_fixed: true },
}

const userSlice = createSlice({
  name: 'user',
  initialState: initialState,
  reducers: {
    logout: () => initialState,
    setUser: (state, action) => {
      state.isAuthenticated = true
      state.isAdmin = action.payload.isAdmin
      state.userData = {
        ...action.payload.user,
        graph_id: action.payload.graph_id,
        token: action.payload.token,
        user_profile: action.payload.userprofile,
      }
    },
    setProfile: (state, action) => {
      state.userData = {
        email: state.userData.email,
        id: state.userData.id,
        username: state.userData.username,
        token: state.userData.token,
        user_profile: action.payload,
      }
    },
    setUsername: (state, action) => {
      state.userData = {
        ...state.userData,
        username: action.payload,
      }
    },
    setThemes: (state, action) => {
      state.userTheme = action.payload
    },
    resetThemes: (state) => {
      state.userTheme = initialState.userTheme
    },
  },
})

export default userSlice.reducer

export const { logout, setUser, setProfile, setUsername, setThemes, resetThemes } =
  userSlice.actions
