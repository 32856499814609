import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { APIS } from 'src/utility/apis'
import { API_ENDPOINT } from 'src/utility/constants'

export const accountUserApi = createApi({
  reducerPath: 'accountUser',
  baseQuery: fetchBaseQuery({
    baseUrl: API_ENDPOINT,
    prepareHeaders: (headers, { getState }) => {
      const token = getState()?.auth?.userData?.token

      headers.set('Accept', 'application/json')
      token && headers.set('Authorization', `Token ${token}`)
    },
  }),
  endpoints: (builder) => ({
    getUserRule: builder.query({
      query: () => ({
        url: APIS.user.users,
      }),
    }),
  }),
})

export const { useGetUserRuleQuery } = accountUserApi
