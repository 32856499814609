import React, { useEffect, useRef } from 'react'
import { cilChatBubble, cilSend, cilX } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import { useState } from 'react'
import { CImage, CSpinner } from '@coreui/react'
import Logo from '../assets/images/icon_only.png'
import { useSelector } from 'react-redux'
import { API_ENDPOINT, AVATARS } from 'src/utility/constants'
import avatar8 from '../assets/images/avatars/user.png'
import axios from 'axios'
import { Bar } from 'react-chartjs-2'
import ChatEnlargeModal from './common/ChatEnlargeModal'
import moment from 'moment'

const sampleQuestions = [
  'Where do I rank?',
  'What was my best operation?',
  'Who ranks 10th?',
  'On what day did I make the least part?',
]
const firstMessage = [
  {
    type: 'left',
    message: `Got any questions? We are happy to help.`,
    response: false,
    is_me: false,
  },
]

const Chat = () => {
  const { isAuthenticated, isAdmin, userData } = useSelector((state) => state.auth)
  const [openClose, setOpenClose] = useState(false)
  const [initialLoad, setInitialLoad] = useState(false)
  const [loading, setLoading] = useState(false)
  const [messages, setMessages] = useState(firstMessage)
  const [leaderboard, setLeaderboard] = useState([])
  const [enlarge, setEnlarge] = useState(false)
  const [enData, setEnData] = useState([])
  const chatBodyRef = useRef(null)
  const contentEditableRef = useRef(null)

  useEffect(() => {
    openClose && leaderboard.length === 0 && loadLeaderboard()
  }, [openClose])

  useEffect(() => {
    if (chatBodyRef.current) {
      chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight
    }
  }, [messages, openClose])

  const loadLeaderboard = async () => {
    setInitialLoad(true)

    await axios
      .post(
        `${API_ENDPOINT}/aiassist/aiassistprompt/`,
        { prompt: 'What is my rank?' },
        {
          headers: {
            Authorization: `Token ${userData?.token}`,
          },
        },
      )
      .then((res) => {
        if (res?.data) {
          setLeaderboard(res?.data?.result?.data || [])
          setInitialLoad(false)
        }
      })
      .catch(() => {
        setInitialLoad(false)
      })
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      sendMessage()
    }
  }

  const askQuestion = async (ques) => {
    setLoading(true)

    const newMsg = [
      ...messages,
      { type: 'right', message: ques, response: false, is_me: false },
      { type: 'left', message: '...', response: false, is_me: false },
    ]
    setMessages(newMsg)

    await axios
      .post(
        `${API_ENDPOINT}/aiassist/aiassistprompt/`,
        { prompt: ques },
        {
          headers: {
            Authorization: `Token ${userData?.token}`,
          },
        },
      )
      .then((res) => {
        if (res?.data) {
          newMsg.pop()
          newMsg.push({
            type: 'left',
            message:
              Array.isArray(res?.data?.result) || Array.isArray(res?.data?.result?.data)
                ? res?.data?.result
                : res?.data?.response || res?.data?.result,
            response: Array.isArray(res?.data?.result) || Array.isArray(res?.data?.result?.data),
            is_me: res?.data?.is_me || false,
          })
        }

        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }

  const sendMessage = async () => {
    const contenteditable = document.querySelector('[contenteditable]')
    const text = contenteditable.textContent
    contenteditable.textContent = ''

    if (text) {
      setLoading(true)

      const newMsg = [
        ...messages,
        { type: 'right', message: text, response: false, is_me: false },
        { type: 'left', message: '...', response: false, is_me: false },
      ]
      setMessages(newMsg)

      await axios
        .post(
          `${API_ENDPOINT}/aiassist/aiassistprompt/`,
          { prompt: text },
          {
            headers: {
              Authorization: `Token ${userData?.token}`,
            },
          },
        )
        .then((res) => {
          if (res?.data) {
            newMsg.pop()
            newMsg.push({
              type: 'left',
              message:
                Array.isArray(res?.data?.result) || Array.isArray(res?.data?.result?.data)
                  ? res?.data?.result
                  : res?.data?.response || res?.data?.result,
              response: Array.isArray(res?.data?.result) || Array.isArray(res?.data?.result?.data),
              is_me: res?.data?.is_me || false,
            })
          }

          setLoading(false)
        })
        .catch(() => {
          setLoading(false)
        })
    }
  }

  const generateLeaderboard = (response) => {
    const filter = response?.find((item) => item?.highlight)
    const names = response?.map((item) => item?.operator_name)
    const backgroundColors = response?.map((item) => (item?.highlight ? 'red' : '#cccccc'))
    const borderColors = response?.map((item) => (item?.highlight ? 'red' : '#cccccc'))
    const datasets = [
      {
        label: 'Leaderboard',
        backgroundColor: backgroundColors,
        borderColor: borderColors,
        borderWidth: 1,
        data: response?.map((item) => item?.rank),
      },
    ]

    return (
      <div className="left-bubble">
        <CImage src={Logo} alt="Oding Engage" height={30} />
        <div className="message">
          <p>
            Based on your performance your rank is <b>{filter?.rank}</b> and leaderboard is below,
          </p>
          {/* <CTable className="mb-0">
            <CTableHead>
              <CTableRow>
                <CTableHeaderCell>Operator name</CTableHeaderCell>
                <CTableHeaderCell>No.of parts</CTableHeaderCell>
                <CTableHeaderCell>Rank</CTableHeaderCell>
              </CTableRow>
            </CTableHead>
            <CTableBody>
              {response?.map((item, index) => (
                <CTableRow key={index.toString()}>
                  <CTableDataCell style={item?.highlight ? { background: '#FADDC7' } : {}}>
                    {item?.operator_name}
                  </CTableDataCell>
                  <CTableDataCell style={item?.highlight ? { background: '#FADDC7' } : {}}>
                    {item?.no_of_parts}
                  </CTableDataCell>
                  <CTableDataCell style={item?.highlight ? { background: '#FADDC7' } : {}}>
                    {item?.rank}
                  </CTableDataCell>
                </CTableRow>
              ))}
            </CTableBody>
          </CTable> */}
          <Bar
            data={{
              labels: names,
              datasets: datasets,
            }}
            options={{
              responsive: true,
              plugins: {
                legend: {
                  display: false,
                },
                tooltip: {
                  callbacks: {
                    label: function (context) {
                      const value = context.parsed.y || 0
                      const parts = response[context.dataIndex]?.no_of_parts || 0
                      return `Rank: ${value}, # of Parts: ${parts}`
                    },
                  },
                },
              },
              scales: {
                y: {
                  beginAtZero: true,
                },
              },
            }}
          />
          <p className="mb-0 fs-12 pointer" onClick={() => setEnlargeData(response)}>
            <u>View leaderboard</u>
          </p>
        </div>
      </div>
    )
  }

  const generateResponse = (response, is_me = false) => {
    if (Array.isArray(response?.data)) {
      if (response?.type === 1) {
        const names = response?.data?.map((item) => item?.operator_name)
        const backgroundColors = response?.data?.map((item) =>
          item?.highlight ? 'red' : '#cccccc',
        )
        const borderColors = response?.data?.map((item) => (item?.highlight ? 'red' : '#cccccc'))
        const datasets = [
          {
            label: 'Leaderboard',
            backgroundColor: backgroundColors,
            borderColor: borderColors,
            borderWidth: 1,
            data: response?.data?.map((item) => item?.rank),
          },
        ]

        return (
          <>
            <Bar
              data={{
                labels: names,
                datasets: datasets,
              }}
              options={{
                responsive: true,
                plugins: {
                  legend: {
                    display: false,
                  },
                  tooltip: {
                    callbacks: {
                      label: function (context) {
                        const value = context.parsed.y || 0
                        const parts = response?.data[context.dataIndex]?.no_of_parts || 0
                        return `Rank: ${value}, # of Parts: ${parts}`
                      },
                    },
                  },
                },
                scales: {
                  y: {
                    beginAtZero: true,
                  },
                },
              }}
            />
            <p className="mb-0 fs-12 pointer" onClick={() => setEnlargeData(response?.data)}>
              <u>View leaderboard</u>
            </p>
          </>
        )
      }
    } else {
      return response?.map((item, i) => {
        const properties = Object.keys(item)

        if (item?.is_best_operation === true || item?.is_best_operation === false) {
          return (
            <p className="mb-0" key={i.toString()}>
              {is_me ? 'My' : ''} {item?.is_best_operation === true ? 'best' : 'worst'} operation is{' '}
              <b>{item['Operation Name']}</b>
            </p>
          )
        } else {
          const html = properties.map((prop, index) => (
            <p className="mb-0" key={index.toString()}>
              {prop?.toLowerCase()?.includes('date') ? (
                moment(item[prop]).format('Do MMM YYYY - dddd')
              ) : prop?.toLowerCase()?.includes('no_of_parts') ? (
                <span>
                  No. of parts did by me is <b>{item[prop]}</b>
                </span>
              ) : (
                `${prop}: ${item[prop]}`
              )}
            </p>
          ))
          return html
        }
      })
    }
  }

  const setEnlargeData = (data) => {
    setEnlarge(true)
    setEnData(data)
  }

  return isAuthenticated && !isAdmin ? (
    <>
      <div className="chat">
        {openClose && (
          <div className="chat-open">
            <div className="chat-header">
              <CImage src={Logo} alt="Oding Engage" height={50} />
              <h5 className="mb-0 ms-3">ODIN Chat</h5>
            </div>
            <div className="chat-body">
              <ul>
                {sampleQuestions.map((item, index) => (
                  <li className="pointer" onClick={() => askQuestion(item)} key={index.toString()}>
                    {item}
                  </li>
                ))}
              </ul>
              <div className="messages" ref={chatBodyRef}>
                {initialLoad ? (
                  <CSpinner size="sm" color="primary" />
                ) : (
                  <>
                    <div className="left-bubble">
                      <CImage src={Logo} alt="Oding Engage" height={30} />
                      <div className="message">
                        <p className="mb-0">
                          Welcome <b>{userData?.first_name || userData?.username}</b>
                        </p>
                      </div>
                    </div>
                    {generateLeaderboard(leaderboard)}
                    {messages?.map((item, index) => (
                      <React.Fragment key={index.toString()}>
                        {item?.type === 'left' ? (
                          <div className="left-bubble">
                            <CImage src={Logo} alt="Oding Engage" height={30} />
                            <div className="message">
                              {item?.response
                                ? generateResponse(item?.message, item?.is_me)
                                : item?.message}
                            </div>
                          </div>
                        ) : (
                          <div className="right-bubble">
                            <CImage
                              src={
                                userData?.user_profile?.avatar_id
                                  ? AVATARS[userData?.user_profile?.avatar_id - 1]
                                  : avatar8
                              }
                              alt="Oding Engage"
                              height={30}
                            />
                            <div className="message">{item?.message}</div>
                          </div>
                        )}
                      </React.Fragment>
                    ))}
                  </>
                )}
              </div>
            </div>
            <div className="chat-footer">
              <div
                contentEditable={true}
                onKeyDown={handleKeyDown}
                ref={contentEditableRef}
                placeholder="Write a message"
                className="contenteditable"
              ></div>
              <CIcon
                icon={cilSend}
                size="lg"
                className={loading ? 'no-click' : 'pointer'}
                onClick={sendMessage}
              />
            </div>
          </div>
        )}
        <p className="chatIcon" onClick={() => setOpenClose(!openClose)}>
          <CIcon icon={openClose ? cilX : cilChatBubble} size="xl" />
        </p>
      </div>
      <ChatEnlargeModal show={enlarge} setShow={setEnlarge} data={enData} />
    </>
  ) : (
    ''
  )
}

export default Chat
