import { combineReducers } from 'redux'
import { rewardsApi } from './services/rewards'
import { challengesApi } from './services/challenges'
import SidebarReducer from './feature/sidebarSlice'
import UserReducer from './feature/userSlice'
import { usersApi } from './services/users'
import { productsApi } from './services/products'
import { pollsApi } from './services/polls'
import { notificationApi } from './services/notification'
import { accountUserApi } from './services/accountUser'
import { leadershipApi } from './services/leadership'

const appReducer = combineReducers({
  sidebar: SidebarReducer,
  auth: UserReducer,
  [usersApi.reducerPath]: usersApi.reducer,
  [rewardsApi.reducerPath]: rewardsApi.reducer,
  [challengesApi.reducerPath]: challengesApi.reducer,
  [productsApi.reducerPath]: productsApi.reducer,
  [pollsApi.reducerPath]: pollsApi.reducer,
  [notificationApi.reducerPath]: notificationApi.reducer,
  [accountUserApi.reducerPath]: accountUserApi.reducer,
  [leadershipApi.reducerPath]: leadershipApi.reducer,
})

export const middlewares = [
  usersApi.middleware,
  rewardsApi.middleware,
  challengesApi.middleware,
  productsApi.middleware,
  pollsApi.middleware,
  notificationApi.middleware,
  accountUserApi.middleware,
  leadershipApi.middleware,
]

export const rootReducer = (state, action) => {
  if (action.type === 'CLEAR_STORE') return appReducer(undefined, action)
  else return appReducer(state, action)
}
