export const APIS = {
  user: {
    login: 'account/login/',
    register: 'account/register/',
    profile: 'account/userprofile',
    users: 'account/user_details',
    check: 'account/checkaccount/',
    updateUsername: 'account/operator_details/',
  },
  notification: { points: 'generic/loginnotification/' },
  rewards: {
    points: 'rewards/rewards/',
    rewardMaster: 'rewards/reward_master/',
    rewardRule: 'rewards/reward_rule/',
    rewardCategory: 'rewards/reward_cat/',
    summary: 'rewards/reward_statement/',
  },
  challenge: {
    rule: 'challenges/challenge_rule/',
    points: 'challenges/challenges/user_challenges/',
    confirm: 'challenges/challenges/challenge_confirmation/',
    complete: 'challenges/challenges/',
    generic: 'generic/challenges',
  },
  polls: {
    common: 'polls/polls/',
    vote: 'polls/pollattempt/',
    result: 'polls/pollsresult/',
  },
  leadership: {
    points: 'generic/leaderboard',
    rule: 'generic/graph',
  },
  trending: {
    rule: 'generic/fasterslowertrending',
  },
}
