/* eslint-disable react/prop-types */
import { CModal, CModalBody, CModalFooter, CModalHeader, CModalTitle } from '@coreui/react'
import React from 'react'
import { Button } from 'react-bootstrap'
import { Bar } from 'react-chartjs-2'

const ChatEnlargeModal = ({ show, setShow, data }) => {
  const handleClose = () => setShow(false)

  const names = data?.map((item) => item?.operator_name)
  const backgroundColors = data?.map((item) => (item?.highlight ? 'red' : '#cccccc'))
  const borderColors = data?.map((item) => (item?.highlight ? 'red' : '#cccccc'))
  const datasets = [
    {
      label: 'Leaderboard',
      backgroundColor: backgroundColors,
      borderColor: borderColors,
      borderWidth: 1,
      data: data?.map((item) => item?.rank),
    },
  ]

  return (
    <CModal
      alignment="center"
      visible={show}
      backdrop="static"
      size="md"
      onClose={() => setShow(false)}
    >
      <CModalHeader>
        <CModalTitle>Leaderboard</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <Bar
          data={{
            labels: names,
            datasets: datasets,
          }}
          options={{
            responsive: true,
            plugins: {
              legend: {
                display: false,
              },
              tooltip: {
                callbacks: {
                  label: function (context) {
                    const value = context.parsed.y || 0
                    const parts = data[context.dataIndex]?.no_of_parts || 0
                    return `Rank: ${value}, # of Parts: ${parts}`
                  },
                },
              },
            },
            scales: {
              y: {
                beginAtZero: true,
              },
            },
          }}
        />
      </CModalBody>
      <CModalFooter>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </CModalFooter>
    </CModal>
  )
}

export default ChatEnlargeModal
