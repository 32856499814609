import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { APIS } from 'src/utility/apis'
import { API_ENDPOINT } from 'src/utility/constants'

export const productsApi = createApi({
  reducerPath: 'products',

  baseQuery: fetchBaseQuery({
    baseUrl: API_ENDPOINT,
    prepareHeaders: (headers, { getState }) => {
      const token = getState()?.auth?.userData?.token

      headers.set('Accept', 'application/json')
      token && headers.set('Authorization', `Token ${token}`)
    },
  }),
  tagTypes: ['Products'],
  endpoints: (builder) => ({
    getProducts: builder.query({
      query: () => ({
        url: APIS.rewards.rewardMaster,
      }),
      providesTags: ['Products'],
    }),
    addProduct: builder.mutation({
      query: (payload) => ({ url: APIS.rewards.rewardMaster, method: 'POST', body: payload }),
      transformResponse: (result) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          console.log(data, 'data from api')
          // dispatch(setUser(data))
        } catch (error) {}
      },
    }),
    deleteProduct: builder.mutation({
      query: (product_id) => ({
        url: APIS.rewards.rewardMaster + product_id + '/',
        method: 'DELETE',
      }),
      providesTags: ['Products'],
      transformResponse: (result) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          console.log(data, 'data from api')
          // dispatch(setUser(data))
        } catch (error) {}
      },
    }),
  }),
})

export const { useGetProductsQuery, useAddProductMutation, useDeleteProductMutation } = productsApi
