import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { APIS } from 'src/utility/apis'
import { API_ENDPOINT } from 'src/utility/constants'

export const challengesApi = createApi({
  reducerPath: 'challenge',
  baseQuery: fetchBaseQuery({
    baseUrl: API_ENDPOINT,
    prepareHeaders: (headers, { getState }) => {
      const token = getState()?.auth?.userData?.token

      headers.set('Accept', 'application/json')
      token && headers.set('Authorization', `Token ${token}`)
    },
  }),
  endpoints: (builder) => ({
    getChallengePoints: builder.query({
      query: () => ({
        url: APIS.challenge.points,
      }),
    }),
    getChallengeGraphPoints: builder.query({
      query: () => ({
        url: APIS.challenge.generic,
      }),
    }),
    getChallengeRule: builder.query({
      query: () => ({
        url: APIS.challenge.rule,
      }),
    }),
    addChallengeRule: builder.mutation({
      query: (payload) => ({ url: APIS.challenge.rule, method: 'POST', body: payload }),
    }),
    completeChallengeRule: builder.mutation({
      query: ({ id, payload }) => ({
        url: `${APIS.challenge.complete}${id}/`,
        method: 'PATCH',
        body: payload,
      }),
    }),
    deleteChallengeRule: builder.mutation({
      query: (payload) => ({
        url: `${APIS.challenge.rule}${payload?.id}/`,
        method: 'DELETE',
      }),
    }),
    confirmChallengeRule: builder.mutation({
      query: (payload) => ({ url: APIS.challenge.confirm, method: 'POST', body: payload }),
    }),
  }),
})

export const {
  useGetChallengePointsQuery,
  useGetChallengeRuleQuery,
  useAddChallengeRuleMutation,
  useCompleteChallengeRuleMutation,
  useConfirmChallengeRuleMutation,
  useDeleteChallengeRuleMutation,
  useGetChallengeGraphPointsQuery,
} = challengesApi
