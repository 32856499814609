import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { APIS } from 'src/utility/apis'
import { API_ENDPOINT } from 'src/utility/constants'

export const notificationApi = createApi({
  reducerPath: 'notification',
  baseQuery: fetchBaseQuery({
    baseUrl: API_ENDPOINT,
    prepareHeaders: (headers, { getState }) => {
      const token = getState()?.auth?.userData?.token

      headers.set('Accept', 'application/json')
      token && headers.set('Authorization', `Token ${token}`)
    },
  }),
  endpoints: (builder) => ({
    getNotificationRule: builder.query({
      query: () => ({
        url: APIS.notification.points,
      }),
    }),
    updateNotificationRule: builder.mutation({
      query: (payload) => ({ url: APIS.notification.points, method: 'POST', body: payload }),
    }),
  }),
})

export const { useGetNotificationRuleQuery, useUpdateNotificationRuleMutation } = notificationApi
