import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { APIS } from 'src/utility/apis'
import { API_ENDPOINT } from 'src/utility/constants'

export const rewardsApi = createApi({
  reducerPath: 'rewards',
  baseQuery: fetchBaseQuery({
    baseUrl: API_ENDPOINT,
    prepareHeaders: (headers, { getState }) => {
      const token = getState()?.auth?.userData?.token

      headers.set('Accept', 'application/json')
      token && headers.set('Authorization', `Token ${token}`)
    },
  }),
  endpoints: (builder) => ({
    getRewardPoints: builder.query({
      query: ({ user_id }) => ({
        url: `${APIS.rewards.points}${user_id}/`,
      }),
    }),
    getRewardCategory: builder.query({
      query: () => ({
        url: APIS.rewards.rewardCategory,
      }),
    }),
    getRewardRule: builder.query({
      query: () => ({
        url: APIS.rewards.rewardRule,
      }),
    }),
    addRewardRule: builder.mutation({
      query: (payload) => ({ url: APIS.rewards.rewardRule, method: 'POST', body: payload }),
    }),
    deleteRewardRule: builder.mutation({
      query: (rule_id) => ({
        url: APIS.rewards.rewardRule + rule_id + '/',
        method: 'DELETE',
      }),
    }),
    getRewardStatement: builder.query({
      query: ({ page, limit, search = '', rewarded_date__gte = '', rewarded_date__lte = '' }) => ({
        url: APIS.rewards.summary,
        params: { page, limit, search, rewarded_date__gte, rewarded_date__lte },
      }),
    }),
  }),
})

export const {
  useGetRewardPointsQuery,
  useAddRewardRuleMutation,
  useGetRewardCategoryQuery,
  useGetRewardRuleQuery,
  useDeleteRewardRuleMutation,
  useGetRewardStatementQuery,
} = rewardsApi
