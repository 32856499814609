import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { APIS } from 'src/utility/apis'
import { API_ENDPOINT } from 'src/utility/constants'
import { setProfile, setUser, setUsername } from '../feature/userSlice'

export const usersApi = createApi({
  reducerPath: 'users',
  baseQuery: fetchBaseQuery({
    baseUrl: API_ENDPOINT,
    prepareHeaders: (headers, { getState }) => {
      const token = getState()?.auth?.userData?.token

      headers.set('Accept', 'application/json')
      token && headers.set('Authorization', `Token ${token}`)
    },
  }),
  endpoints: (builder) => ({
    loginUsers: builder.mutation({
      query: (payload) => ({ url: APIS.user.login, method: 'POST', body: payload }),
      transformResponse: (result) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          dispatch(setUser(data))
        } catch (error) {}
      },
    }),
    checkAccount: builder.mutation({
      query: (payload) => ({ url: APIS.user.check, method: 'POST', body: payload }),
    }),
    registerUsers: builder.mutation({
      query: (payload) => ({ url: APIS.user.register, method: 'POST', body: payload }),
      transformResponse: (result) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          dispatch(setUser(data))
        } catch (error) {}
      },
    }),
    updateProfile: builder.mutation({
      query: ({ userId, payload }) => ({
        url: `${APIS.user.profile}/${userId}/`,
        method: 'PATCH',
        body: payload,
      }),
      transformResponse: (result) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          dispatch(setProfile(data))
        } catch (error) {}
      },
    }),
    updateUsername: builder.mutation({
      query: ({ payload }) => ({
        url: `${APIS.user.updateUsername}`,
        method: 'POST',
        body: payload,
      }),
      transformResponse: (result) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          data && dispatch(setUsername(args?.payload?.username))
        } catch (error) {}
      },
    }),
  }),
})

export const {
  useLoginUsersMutation,
  useCheckAccountMutation,
  useRegisterUsersMutation,
  useUpdateProfileMutation,
  useUpdateUsernameMutation,
} = usersApi
