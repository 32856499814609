import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { APIS } from 'src/utility/apis'
import { API_ENDPOINT } from 'src/utility/constants'

export const pollsApi = createApi({
  reducerPath: 'polls',
  baseQuery: fetchBaseQuery({
    baseUrl: API_ENDPOINT,
    prepareHeaders: (headers, { getState }) => {
      const token = getState()?.auth?.userData?.token

      headers.set('Accept', 'application/json')
      token && headers.set('Authorization', `Token ${token}`)
    },
  }),
  endpoints: (builder) => ({
    getAllPoll: builder.query({
      query: () => ({ url: APIS.polls.common }),
    }),
    createPoll: builder.mutation({
      query: (payload) => ({ url: APIS.polls.common, method: 'POST', body: payload }),
    }),
    votePoll: builder.mutation({
      query: (payload) => ({ url: APIS.polls.vote, method: 'POST', body: payload }),
    }),
    getPollResults: builder.mutation({
      query: (payload) => ({ url: APIS.polls.result, method: 'POST', body: payload }),
    }),
  }),
})

export const {
  useGetAllPollQuery,
  useCreatePollMutation,
  useVotePollMutation,
  useGetPollResultsMutation,
} = pollsApi
